import { computed, isReadonly, isRef, ref, watch, reactive } from 'vue';
import type { ComputedRef, Ref, UnwrapNestedRefs } from 'vue';

/**
 * Интерфейс опций пагинации.
 */
export interface IPaginationOptions {
  /**
   * Коллбек подгрузки следующей страницы.
   */
  onLoadMore?: (returnValue: UnwrapNestedRefs<IPaginationOptionsReturn>) => unknown;
  /**
   * Коллбек смены текущей страницы.
   */
  onPageChange?: (returnValue: UnwrapNestedRefs<IPaginationOptionsReturn>) => unknown;
  /**
   * Коллбек изменения количества страниц.
   */
  onPageCountChange?: (returnValue: UnwrapNestedRefs<IPaginationOptionsReturn>) => unknown;
  /**
   * Коллбек изменения размера страницы.
   */
  onPageSizeChange?: (returnValue: UnwrapNestedRefs<IPaginationOptionsReturn>) => unknown;
  /**
   * Текущая страница.
   * @default 1
   */
  page?: number | Ref<number>;
  /**
   * Количество элементов на странице.
   * @default 10
   */
  pageSize?: number | Ref<number>;
  /**
   * Общее количество элементов.
   */
  total?: number | Ref<number>;
}

/**
 * Интерфейс возвращаемого значения из функции пагинации.
 */
export interface IPaginationOptionsReturn {
  currentPage: Ref<number>;
  currentPageSize: Ref<number>;
  isFirstPage: ComputedRef<boolean>;
  isLastPage: ComputedRef<boolean>;
  loadMore: () => void;
  next: () => void;
  pageCount: ComputedRef<number>;
  prev: () => void;
}

/**
 * Тип возвращаемого значения для пагинации с бесконечным количеством страниц.
 */
export type UseOffsetPaginationInfinityPageReturn = Omit<IPaginationOptionsReturn, 'isLastPage'>;

/**
 * Хук для использования пагинации.
 * @param options Опции для пагинации.
 * @returns Возвращает объект с параметрами и функциями для управления пагинацией.
 */
export function usePagination(options: Omit<IPaginationOptions, 'total'>): UseOffsetPaginationInfinityPageReturn;
export function usePagination(options: IPaginationOptions): IPaginationOptionsReturn;
export function usePagination(options: IPaginationOptions): IPaginationOptionsReturn {
  const {
    total = Number.POSITIVE_INFINITY,
    pageSize = 10,
    page = 1,
    onPageChange = () => {},
    onPageSizeChange = () => {},
    onPageCountChange = () => {},
    onLoadMore = () => {},
  } = options;

  const currentPageSize = ref(pageSize);
  const totalValue = ref(total);
  const currentPage = ref(page);

  if (isRef(pageSize)) {
    watch(pageSize, (newVal) => {
      currentPageSize.value = newVal;
    });
  }

  if (isRef(total)) {
    watch(total, (newVal) => {
      totalValue.value = newVal;
    });
  }

  const pageCount = computed(() => Math.max(1, Math.ceil(totalValue.value / currentPageSize.value)));

  watch([currentPage, pageCount], () => {
    if (currentPage.value < 1) {
      currentPage.value = 1;
    } else if (currentPage.value > pageCount.value) {
      currentPage.value = pageCount.value;
    }
  });

  const isFirstPage = computed(() => currentPage.value === 1);
  const isLastPage = computed(() => currentPage.value === pageCount.value);

  if (isRef(page)) {
    watch(currentPage, (newVal) => {
      if (!isReadonly(page)) {
        page.value = newVal;
      }
    });
  }

  if (isRef(pageSize)) {
    watch(currentPageSize, (newVal) => {
      if (!isReadonly(pageSize)) {
        pageSize.value = newVal;
      }
    });
  }

  function prev() {
    if (currentPage.value > 1) {
      currentPage.value--;
    }
  }

  function next() {
    if (currentPage.value < pageCount.value) {
      currentPage.value++;
    }
  }

  function loadMore() {
    currentPage.value++;
    onLoadMore(reactive(returnValue));
  }

  const returnValue = {
    currentPage,
    currentPageSize,
    isFirstPage,
    isLastPage,
    loadMore,
    next,
    pageCount,
    prev,
  };

  watch(currentPage, () => {
    onPageChange(reactive(returnValue));
  });

  watch(currentPageSize, () => {
    onPageSizeChange(reactive(returnValue));
  });

  watch(pageCount, () => {
    onPageCountChange(reactive(returnValue));
  });

  return returnValue;
}
